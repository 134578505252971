// import FontFaceObserver from 'fontfaceobserver';
import { ICON_SPRITE_URL } from './constants';

document.documentElement.className += ' has-optimized-font has-fonts';

// function fontLoader() {
//   // Optimization for Repeat Views
//   if (sessionStorage.fontsLoadedCriticalFoftPreloadPolyfillSTIP) {
//     document.documentElement.className += ' has-optimized-font has-fonts';
//     return;
//   }

//   const fontPrimaryOptimized = new FontFaceObserver('Lexend Optimized');

//   Promise.all([fontPrimaryOptimized.load(null, 10000)]).then(() => {
//     document.documentElement.className += ' has-optimized-font';

//     const fontPrimary = new FontFaceObserver('Jost');
//     const fontPrimaryBold = new FontFaceObserver('Jost', { weight: 700 });

//     Promise.all([
//       fontPrimary.load(null, 10000),
//       fontPrimaryBold.load(null, 10000),
//     ]).then(() => {
//       document.documentElement.className += ' has-optimized-font has-fonts';

//       // Optimization for Repeat Views
//       sessionStorage.fontsLoadedCriticalFoftPreloadPolyfillSTIP = true;
//     });
//   });
// }

// fontLoader();

/**
 * Inject icons
 */
const request = new XMLHttpRequest();
request.open('GET', ICON_SPRITE_URL, true);
request.onreadystatechange = () => {
  if (request.readyState === 4) {
    const div = document.createElement('div');
    const ready = () => document.body.appendChild(div);

    div.setAttribute('style', 'height: 0; width: 0; position: absolute; visibility: hidden; display: none');
    div.innerHTML = request.responseText;

    if (document.readyState === 'complete') {
      ready();
    } else {
      document.addEventListener('DOMContentLoaded', () => ready(), false);
      window.addEventListener('load', () => ready(), false);
    }
  }
};
request.send();
